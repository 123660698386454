import React from 'react';
import { AlertTriangle, Check, Download } from 'lucide-react';

const Upgrade = ({ upgradeData, onClose }) => {
  // Find the objects containing each piece of data
  const titleObj = upgradeData.find(item => item.title);
  const descriptionObj = upgradeData.find(item => item.description);
  const zipFileObj = upgradeData.find(item => item.zipFile);
  const inclusionsObj = upgradeData.find(item => item.inclusions);
  const notesObj = upgradeData.find(item => item.notes);
  const warningsObj = upgradeData.find(item => item.warnings);

  // Extract the actual values
  const title = titleObj?.title || '';
  const description = descriptionObj?.description || '';
  const zipFile = zipFileObj?.zipFile || '';
  const inclusions = inclusionsObj?.inclusions || [];
  const notes = notesObj?.notes || [];
  const warnings = warningsObj?.warnings || [];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-md p-6 w-full max-w-4xl mx-4 max-h-[82vh] overflow-y-auto">
        {/* Header section */}
        <div className="mb-4">
          <h2 className="text-2xl font-bold text-gray-800">{title}</h2>
        </div>

        {/* Description section */}
        {description && (
          <p className="text-gray-600 mb-6">{description}</p>
        )}

        {/* Download button */}
        {zipFile && (
          <div className="bg-white border border-gray-200 rounded-lg p-4 mb-6">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                
                <span className="text-gray-700 font-medium">NBS1202 Software Upgrade</span>
              </div>
              <a
                href={zipFile}
                download
                className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors flex items-center space-x-2"
              >
                <Download className="w-4 h-4" />
                <span>Download ZIP</span>
              </a>
            </div>
          </div>
        )}

        {/* What's Included section */}
        {inclusions.length > 0 && (
          <div className="bg-green-50 border-l-4 border-green-500 p-4 mb-6">
            <h3 className="text-lg font-semibold text-green-800 mb-2">What's Included in This Upgrade</h3>
            <ul className="space-y-2">
              {inclusions.map((item, index) => (
                <li key={index} className="flex items-start text-green-700">
                  <Check className="w-5 h-5 mr-2 mt-1 flex-shrink-0" />
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Upgrade steps section */}
        {notes.length > 0 && (
          <div className="bg-blue-50 border-l-4 border-blue-500 p-4 mb-6">
            <h3 className="text-lg font-semibold text-blue-800 mb-2">Upgrade Steps</h3>
            <ol className="list-decimal pl-5 space-y-2 text-blue-700">
              {notes.map((note, index) => (
                <li key={index}>{note}</li>
              ))}
            </ol>
          </div>
        )}

        {/* Warnings section */}
        {warnings.length > 0 && (
          <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-6 flex items-start">
            <AlertTriangle className="text-red-600 mr-3 mt-1 flex-shrink-0" />
            <div>
              <h3 className="text-lg font-semibold text-red-800 mb-2">Important Warnings</h3>
              <ul className="list-disc pl-5 space-y-2 text-red-700">
                {warnings.map((warning, index) => (
                  <li key={index}>{warning}</li>
                ))}
              </ul>
            </div>
          </div>
        )}

        {/* Close button */}
        <div className="mt-6 flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="bg-gray-200 text-gray-800 px-6 py-2 rounded-md hover:bg-gray-300 transition"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Upgrade;