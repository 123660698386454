import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { Loader } from "lucide-react";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import ProductInfo from "../../components/pageProps/productDetails/ProductInfo";
import Control from "../../components/home/Products/Control";
import ImageCarousel from "./ImageCarousel";
import ThreeDModelViewer from "./3DModelViewer";
import Upgrade from './Upgrade';

const tabs = [
  { id: "Fiche Technique", label: "SPECIFICATION" },
  { id: "Features", label: "FEATURES" },
  { id: "Boxs", label: "INSIDE THE BOX" },
];

const ProductDetails = () => {
  const location = useLocation();
  const { productId } = useParams();
  const [productImages, setProductImages] = useState({});
  const [prevLocation, setPrevLocation] = useState("");
  const [productInfo, setProductInfo] = useState({});
  const [controls, setControls] = useState([]);
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [currentColor, setCurrentColor] = useState("default");
  const [is3DModalOpen, setIs3DModalOpen] = useState(false);
  const [loading3D, setLoading3D] = useState(false);
  const [modelPath, setModelPath] = useState("");  
  const [isReadMeOpen, setIsReadMeOpen] = useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false); // Modal state for Upgrade
  const [upgradeData, setUpgradeData] = useState({}); // Store upgrade data here

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  useEffect(() => {
    if (location.state && location.state.item) {
      const item = location.state.item;
      setProductInfo(item);
      setPrevLocation(location.pathname);
      console.log("Product category:", item.cat);
      console.log("Product Info:", item); // Add this line
      if (item.controls) {
        setControls(item.controls);
      }

      if (item.images) {
        setProductImages(item.images);
        const colors = Object.keys(item.images);
        if (colors.length > 0) {
          setCurrentColor(colors[0]);
        }
      } else if (item.img) {
        setProductImages({ default: [item.img] });
        setCurrentColor("default");
      }

      // Set 3D model path
      if (item.modelPath) {
        console.log('Model path received:', item.modelPath);
        setModelPath(item.modelPath);
      } else {
        console.log('No model path available');
        setModelPath("");
      }

     // Set upgrade data (passed as item data)
     if (item.upgradeData) {
      setUpgradeData(item.upgradeData); // Example: { title, description, notes, warnings, downloadLink }
    }
    } 
  }, [location, productId]);

  const handleOpen3DModal = () => {
    setLoading3D(true);
    setIs3DModalOpen(true);
    // Simulate loading time
    setTimeout(() => setLoading3D(false), 2500);
  };

  const ThreeDModal = ({ isOpen, onClose, color, modelPath }) => {
    if (!isOpen) return null;
  if (!modelPath) {
    console.error('No model path provided to ThreeDModal');
    return null;
  }

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white-50 p-4 rounded-lg w-[80vw] h-[80vh]">
          <button onClick={onClose} className="float-right bg-red-500 text-white px-4 py-1 rounded">
            x
          </button>
          {loading3D ? (
            <div className="w-full h-full flex items-center justify-center">
              <Loader className="animate-spin" />
            </div>
          ) : (
            <div style={{ width: '100%', height: '100%' }}>
              <ThreeDModelViewer modelPath={modelPath} color={color} />
            </div>
          )}
        </div>
      </div> 
    );
  };
 
  return (
    <Wrapper>
      <div className="w-full mx-auto border-b-[1px] border-b-gray-300">
        <div className="max-w-container mx-auto px-4">
          <div className="xl:-mt-10 -mt-7">
            <Breadcrumbs title="" prevLocation={prevLocation} />
          </div>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-6 gap-4 h-full -mt-5 xl:-mt-8 pb-10 hover:text-black dark:hover:text-white duration-200 p-4">
            <div className="w-full h-full xl:col-span-2">
              {productImages &&
                currentColor &&
                Object.keys(productImages).length > 0 && (
                  <ImageCarousel
                    images={productImages}
                    currentColor={currentColor}
                    setCurrentColor={setCurrentColor}
                  />
                )}
              {modelPath && (
                <button
                  className="bg-red-500 text-white dark:hover:text-white py-3 px-6 
               rounded-full mt-4"
                  onClick={handleOpen3DModal}
                >
                  Show 3D View
                </button>
              )}
           {productInfo.readMeContent && (
                <button
                  className="bg-green-500 text-white dark:hover:text-white py-3 px-6 rounded-full mt-4"
                  onClick={() => setIsReadMeOpen(true)}
                >
                  README
                </button>
              )}
              {upgradeData && upgradeData.length > 0 && (
                <button
                  className="bg-orange-500 text-white py-3 px-6 rounded-full mt-4"
                  onClick={() => setIsUpgradeModalOpen(true)}
                >
                  READ ME
                </button>
              )}
            </div>

            <div className="h-full w-full md:col-span-2 xl:col-span-4 xl:px-4 flex flex-col gap-6 justify-center">
              <ProductInfo productInfo={productInfo} />
            </div>
          </div>
          <hr />
          <div className="content">
            {controls && controls.length > 0 && (
              <>
                <h3 className="controls-title">Tap Controls:</h3>
                <div className="controls-grid pt-4">
                  {controls.slice(0, 6).map((control, index) => (
                    <Control
                      key={index}
                      img={control.img}
                      title={control.title}
                      description={control.description}
                    />
                  ))}
                </div>
              </>
            )}
          </div>

          <div className="pt-4">
            <div className="space-x-0 sm:space-x-4 space-between gap-8">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  className={`${
                    activeTab === tab.id
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-800"
                  } py-2 px-8 sm:px-10 gap-8 focus:outline-none`}
                  onClick={() => handleTabClick(tab.id)}
                >
                  {tab.label}
                </button>
              ))}
            </div>
            <div className="my-4">
              {tabs.map((tab) => (
                <div
                  key={tab.id}
                  className={activeTab === tab.id ? "" : "hidden"}
                >
                  {tab.id === "Fiche Technique" && productInfo.ficheTech ? (
                    <div>
                      <table className="table-auto w-full">
                        <tbody>
                          {productInfo.ficheTech.map((row, index) => (
                            <tr
                              key={index}
                              className="hover:text-black dark:hover:text-white duration-200 p-2"
                            >
                              <td className="border px-4 py-2 font-semibold">
                                {row.label}
                              </td>
                              <td className="border px-4 py-2">{row.value}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : tab.id === "Features" && productInfo.features ? (
                    <div>
                      <table className="table-auto w-full">
                        <tbody>
                          {productInfo.features.map((feature, index) => (
                            <tr
                              key={index}
                              className="hover:text-black dark:hover:text-white duration-200 p-2"
                            >
                              <td className="border pl-2 pr-0 justify-center items-center font-semibold">
                                {index + 1}
                              </td>
                              <td className="border px-4 py-2">
                                {feature.label}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : tab.id === "Boxs" && productInfo.boxs ? (
                    <div>
                      <table className="table-auto w-full">
                        <tbody>
                          {productInfo.boxs.map((box, index) => (
                            <tr
                              key={index}
                              className="hover:text-black dark:hover:text-white duration-200 p-2"
                            >
                              <td className="border pl-2 pr-0 justify-center items-center font-semibold">
                                {index + 1}
                              </td>
                              <td className="border px-4 py-2">{box.label}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <ThreeDModal
        isOpen={is3DModalOpen}
        onClose={() => setIs3DModalOpen(false)}
        color={currentColor}
        modelPath={modelPath}
      />

          {isUpgradeModalOpen && upgradeData && upgradeData.length > 0 && (
           <Upgrade 
          upgradeData={upgradeData}
          onClose={() => setIsUpgradeModalOpen(false)}
        />
        
      )}
    </Wrapper>
  );
};

export default ProductDetails;

// Styled component wrapper
const Wrapper = styled.div`
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  ow */ hr {
    border-bottom: 1px solid #ccc;
  }

  .content {
    width: 100%;
    margin: 0 auto;
  }

  .breadcrumb-section {
    margin-bottom: 0px;
  }

  .main-content {
    display: grid;
    grid-template-columns: 2fr;
    gap: 20px;

    @media (min-width: 768px) {
      grid-template-columns: 1fr 2fr;
    }
  }

  .image-section {
    .product-image {
      width: 100%;
      height: auto;
    }
  }

  .info-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .controls-title {
    font-size: 1.8rem;
    font-weight: 600;
    margin: 20px 0;
  }

  .controls-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-bottom: 30px;
  }

  .tabs-section {
    margin-top: 20px;

    .tabs {
      display: flex;
      gap: 10px;
      margin-bottom: 10px;
    }

    .tab-button {
      padding: 10px 20px;
      border: none;
      background-color: #f0f0f0;
      color: #333;
      cursor: pointer;
      transition:
        background-color 0.3s,
        color 0.3s;

      &.active {
        background-color: #007bff;
        color: #fff;
      }
    }

    .tab-content {
      .tab-panel {
        display: none;

        &.active {
          display: block;
        }
      }
    }
  }

  .data-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;

    td {
      border: 1px solid #ddd;
      padding: 8px;
    }

    .data-label {
      font-weight: bold;
    }

    .data-index {
      width: 40px;
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    .main-content {
      grid-template-columns: 1fr;
    }

    .controls-title {
      font-size: 1.6rem;
    }

    .data-table {
      font-size: 0.875rem;
    }
  }

  @media (max-width: 480px) {
    .controls-title {
      font-size: 1.4rem;
    }

    .tab-button {
      padding: 8px 16px;
    }

    .data-table {
      font-size: 0.75rem;
    }
  }

  @media (min-width: 768px) {
    .container {
      max-width: 800px;
      padding: 20px 40px;
      font-size: 10px;
    }
  }
  @media (max-width: 868px) {
    .controls-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;
