import React, { useEffect, useState } from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import { SplOfferData } from "../../../constants";
import { useParams } from "react-router-dom";

const SpecialOffers = () => {
  const { category } = useParams();

  const [data, setData] = useState([]);
  useEffect(() => {
    console.log("Fetching data...");
    setData(SplOfferData);
  }, []);


  const catData = data.filter((item) => item.cat === category);
  
  console.log("Category:", category);
  console.log("Filtered Data:", catData);
  
  return (
    <div className="w-full pb-20">
      <Heading heading="" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-3 gap-10">
        {catData.length > 0 ? (
          catData.map((data) => (
          <Product
            key={data._id}
            _id={data._id}
            img={data.img}
            images={data.images}
            productName={data.productName}
            price={data.price}
            color={data.color}
            badge={true}
            input={data.input}
            controls={data.controls}
            modelPath={data.modelPath}
            upgradeData={data.upgradeData}
            des={data.des}
            ficheTech={data.ficheTech}
            features={data.features}
            boxs={data.boxs}
            category={data.cat}
            highlights={data.highlights}
            />
        ))
      ) : (
        <p></p> //A message to check a product stuff No products found in this category.
      )}
      </div>
      
    </div>
  );
};

export default SpecialOffers;

